import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { FormattedMessage } from "gatsby-plugin-intl";

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>
          <FormattedMessage
          id="features_section_subtitle"
          defaultMessage="Features"
          />
      </Subtitle>
      <SectionTitle>
          <FormattedMessage
              id="features_section_title"
              defaultMessage="Smart Fleet Management"
          />
      </SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
            <FeatureTitle>
                <FormattedMessage
                    id="features_fleet_overview_title"
                    defaultMessage="Fleet Overview"
                />
            </FeatureTitle>
            <FeatureText>
                <FormattedMessage
                    id="features_fleet_overview_description"
                    defaultMessage="Benefit from real-time knowledge of your cars their current status."
                />
            </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
              <FormattedMessage
                  id="features_notifications_title"
                  defaultMessage="Notifications"
              />
          </FeatureTitle>
          <FeatureText>
              <FormattedMessage
                  id="features_notifications_description"
                  defaultMessage="Receive maintenance and status alerts based on customisable triggers."
              />
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
            <FeatureTitle><FormattedMessage
                id="features_security_title"
                defaultMessage="Security"
            /></FeatureTitle>
            <FeatureText>
                <FormattedMessage
                    id="features_security_description"
                    defaultMessage="Your data is always safe with us as we use the latest cloud-native technologies."
                />
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle><FormattedMessage
              id="features_automation_title"
              defaultMessage="Automation"
          /></FeatureTitle>
          <FeatureText>
              <FormattedMessage
                  id="features_automation_description"
                  defaultMessage="Create smart automated workflows and triggers to seamlessly manage your cars throughout their lifecycles."
              />
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle><FormattedMessage
              id="features_analytics_title"
              defaultMessage="Analytics"
          /></FeatureTitle>
          <FeatureText>
              <FormattedMessage
                  id="features_analytics_description"
                  defaultMessage="Leverage statistical insights to develop your business and highlight your most profitable cars and clients."
              />
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle><FormattedMessage
              id="features_collaboration_title"
              defaultMessage="Collaboration"
          /></FeatureTitle>
          <FeatureText>
              <FormattedMessage
                  id="features_collaboration_description"
                  defaultMessage="From mechanics to salespeople, everyone in your team can collaborate and benefit from using Kantai."
              />
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
